@use "sass:math";

@import '../config';
@import 'breakpoints';
@import "framework/responsive/tools-mq-from";

// variables

$u-widths__fractions: 12 !default;

// mixin

@mixin u-widths($_columns, $_bp-suffix: null) {

  @each $_denominator in $_columns {

    @for $_numerator from 1 through $_denominator {

      @if ($_numerator != $_denominator or $_denominator == 1) {
        .u-#{$_numerator}\/#{$_denominator}#{$_bp-suffix} {
          width: math.div($_numerator, $_denominator) * 100% !important;
        }
      }
    }
  }
}

@include u-widths($u-widths__fractions);

@each $_bp-name, $_bp-value in $breakpoints {

  @include t-from($_bp-name) {
    @include u-widths($u-widths__fractions, \@#{$_bp-name});
  }
}
