@use "sass:math";

@import "styles/_legacy/breakpoints";
@import "framework/config";
@import "styles/_legacy/variables";
@import "styles/_legacy/keyframes";
@import "styles/config";

@mixin iframe-display {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@mixin css-triangle($color, $direction, $size: $size--lg) {
  $width: $size * 0.75;
  height: $size;
  width: $width;
  @if $direction == right {
    border-top: $size * 0.5 solid transparent;
    border-bottom: $size * 0.5 solid transparent;
    border-left: $width solid $color;
  } @else if $direction == right-down {
    border-top: $size solid transparent;
    border-right: $size solid $color;
  }
}

@mixin sp-stack-children($_spacing-value) {
  > *:not(:last-child) {
    margin-bottom: $_spacing-value;
  }
}

@mixin sp-inline-children($_spacing-value) {
  > *:not(:last-child) {
    margin-right: $_spacing-value;
  }
}

@mixin fluid-container($max-item-number) {
  @for $i from 1 through 4 {
    &:first-child:nth-last-child(#{$i}),
    &:first-child:nth-last-child(#{$i}) ~ & {
      width: #{math.div(100%, $i)};
    }
  }
}

@mixin c-component-hover(
  $edge: "left",
  $size: (
    $size--sm + $size--xs,
  )
) {
  @include c-component-selected($edge, $size, $c-color__brand--primary--light);
}

@mixin c-component-selected(
  $edge: "left",
  $size: (
    $size--sm + $size--xs,
  ),
  $background-color: $c-color__brand--primary--ultra-light
) {
  $valueX: if($edge == "left", 1, if($edge == "right", -1, 0)) * $size;
  $valueY: if($edge == "top", 1, if($edge == "bottom", -1, 0)) * $size;
  background-color: $background-color;
  box-shadow: inset $valueX $valueY 0 0 $c-color__brand--primary;
}

@mixin c-component-selected-gradient(
  $edge: "left",
  $size: (
    $size--sm + $size--xs,
  )
) {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    @if ($edge == "left" or $edge == "right") {
      width: $size;
      height: 100%;
    } @else {
      height: $size;
      width: 100%;
    }
    #{$edge}: 0;
    @content;
  }
}

@mixin vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin full-height {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@mixin until($breakpoint) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    @media (max-width: #{$value - .1}) {
      @content;
    }
  } @else {
    @warn "Wrong breakpoint `#{$breakpoint}`. ";
  }
}

@mixin button-gradient {
  align-items: center;
  background-clip: content-box, border-box;
  background-image: linear-gradient(white, white),
    linear-gradient(
      63deg,
      $c-color__brand--tertiary 30%,
      $c-color__brand--primary 150%
    );
  background-origin: border-box;
  border: solid 1px transparent;
  border-radius: $c-border-radius--sm * 0.5;
  display: flex;
  justify-content: center;
  padding: 0;

  &:hover:not(.ui-state-disabled) {
    background-color: $c-color__brand--primary--dark;
    background-image: unset;
    border-color: $c-color__brand--primary--dark;
  }
}

@mixin gradient-border(
  $_border-size: 1px,
  $_border-radius: calc(#{$c-border-radius} + 1px),
  $_initial-color: $c-color__brand--primary,
  $_final-color: $c-color__brand--tertiary,
  $_background-color: white
) {
  background-clip: content-box, border-box;
  background-image: linear-gradient($_background-color, $_background-color),
    linear-gradient(63deg, $_initial-color 5%, $_final-color 96%);
  background-origin: border-box;
  border: solid $_border-size transparent;
  border-radius: $_border-radius;
}

@mixin incidence-indicator {
  &--item {
    background-color: $c-color__state--danger;
    cursor: pointer;
    display: block;
    height: $size--xl - 4;
    left: $size--lg - 2;
    position: absolute;
    text-align: center;
    top: $size--md;
    width: $size--xl - 4;

    &:hover {
      background-color: lighten($c-color__state--danger, 20%);
    }
  }

  &--icon {
    color: $c-color__neutral--white;
    font-size: $c-font-size--md;
    line-height: normal;
  }
}

@mixin live-icon($animation: true) {
  border-radius: 50%;
  color: $c-color__brand--primary;
  display: block;
  font-size: $c-font-size--xxs;

  @if $animation {
    animation: blinkColor 1s infinite;
  }
}

@mixin p-clear-icon($component) {
  .p-#{$component}-clear-icon {
    line-height: $c-font-size--xs;
    // Hardcoded copy of primeNG class .ui-dropdown-clear-icon. At the moment, there's no way to relate primeNG variables
    position: absolute;
    right: 2em;

    @content;

    &::before {
      font-size: $c-font-size--xs;
    }
  }
}

@mixin show-more-gradient($_direction) {
  background: linear-gradient(
    to #{$_direction},
    $c-color__neutral--white 0%,
    rgba($c-color__neutral--white, 0) 100%
  );
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: $c-space--xxl;
  z-index: 1;
  @if $_direction == right {
    left: 0;
  } @else {
    right: 0;
  }
}

@mixin app-title-style {
  app-title::ng-deep {
    .c-title {
      color: $c-color__neutral--grey--75;
      font-family: $c-font-family--medium;
      font-style: initial;
      font-weight: initial;
      @content;

      &__text {
        text-transform: initial;
      }
    }
  }
}

@mixin un-blurred {
  image-rendering: crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

@mixin containerButton() {
  display: inline-block;
  font-size: $size--ml;
  line-height: $size--lg + 2;
  min-width: $size--xxl + 4;
  padding: 0;

  &--no-data {
    background: transparent !important;
    border: $size--xxs solid transparent !important;
    color: $c-color__neutral--grey--70 !important;
    font-weight: bold;
  }
}

@mixin selected-left-gradient {
  @include c-component-selected-gradient("left", $c-space--sm) {
    background-image: $c-linear-gradient--secondary;
    height: $c-size--sm - 3;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @content;
  }
}

@mixin grid-area-position(
  $rowStart: 1,
  $rowEnd: 2,
  $columnStart: 1,
  $columnEnd: 2
) {
  grid-column-end: $columnEnd;
  grid-column-start: $columnStart;
  grid-row-end: $rowEnd;
  grid-row-start: $rowStart;
}
