@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes halfPopIn {
  from {
    opacity: 0.5;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes blinkColor {
  50% {
    color: transparent;
  }
}


@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate(0, -25px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
