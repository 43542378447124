$min-height--50:    50px !default;
$min-height--100:   100px !default;
$min-height--150:   150px !default;
$min-height--200:   200px !default;
$min-height--500:   500px !default;

$min-heights: (
  '50'  : $min-height--50,
  '100' : $min-height--100,
  '150'  : $min-height--150,
  '200'  : $min-height--200,
  '500'  : $min-height--500,
);

.dev-block {
  background-color: $c-color__neutral--grey--10;
  border: 3px dashed $c-color__neutral--grey--30;
  border-radius: $c-border-radius--sm;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: $c-space--ml;
  }

  @each $_min-height-name, $_min-height-value in $min-heights {
    &--h#{$_min-height-name} {
      min-height: $_min-height-value;
    }
  }

  &__title {
    color: $c-color__neutral--grey--70;
    text-align: center;
  }
}
