@import 'breakpoints';

@mixin u-hidden($direction: from, $breakpoint: null) {

  .u-hidden--#{$direction}\@#{$breakpoint},
  .u-hidden--#{$direction}\@#{$breakpoint} {
    @if ($direction == from) {
      @include t-from($breakpoint) {
        display: none !important;
      }
    }
    @if ($direction == until) {
      @include until($breakpoint) {
        display: none !important;
      }
    }
  }
}

@each $bp-name, $bp-value in $breakpoints {
  @if ($bp-value != 0) {
    @include u-hidden($direction: from, $breakpoint: $bp-name);
    @include u-hidden($direction: until, $breakpoint: $bp-name);
  }
}
