// Foundation
$ratio-8-xxs:                               1*0.125;
$ratio-8-xs:                                1*0.25;
$ratio-8-sm:                                1*0.5;
$ratio-8-md:                                1;
$ratio-8-ml:                                1.25;
$ratio-8-lg:                                2;
$ratio-8-xl:                                3;
$ratio-8-xxl:                               4;

$ratio-1-xxxs:                              1*0.1;
$ratio-1-xxs:                               1*0.2;
$ratio-1-xs:                                1*0.25;
$ratio-1-sm:                                1*0.5;
$ratio-1-md:                                1;
$ratio-1-lg:                                2;
$ratio-1-xl:                                4;
$ratio-1-xxl:                               8;

// Sizes
$base-size:                                 8px;
$size--xxs:                                 $base-size*$ratio-8-xxs; // 1px
$size--xs:                                  $base-size*$ratio-8-xs;  // 2px
$size--sm:                                  $base-size*$ratio-8-sm;  // 4px
$size--md:                                  $base-size*$ratio-8-md;  // 8px
$size--ml:                                  $base-size*$ratio-8-ml;  // 10px
$size--lg:                                  $base-size*$ratio-8-lg;  // 16px
$size--xl:                                  $base-size*$ratio-8-xl;  // 24px
$size--xxl:                                 $base-size*$ratio-8-xxl; // 32px

// Transitions
$base-transition-tf: ease;
$base-transition-duration: 1s;
$transition-duration--xxxs: $base-transition-duration*$ratio-1-xxxs;
$transition-duration--xxs: $base-transition-duration*$ratio-1-xxs;
$transition-duration--xs: $base-transition-duration*$ratio-1-xs;
$transition-duration--sm: $base-transition-duration*$ratio-1-sm;
$transition-duration--md: $base-transition-duration*$ratio-1-md;
$transition-duration--lg: $base-transition-duration*$ratio-1-lg;
$transition-duration--xl: $base-transition-duration*$ratio-1-xl;
$transition-duration--xxl: $base-transition-duration*$ratio-1-xxl;

// Specifics
$shield-size--sm: 30px;
$shield-size--xxl: 64px;
$shield-size: $size--md * 5;

// Layout
$layout__header--size: 65px;
$layout__header--z-index: 5;
$layout__side-nav--z-index: $layout__header--z-index * 2;
$layout__side-nav--size: 200px;
$layout__side-nav--size--sm: 70px;
$layout__side-nav--breakpoint: 'xl';
$layout__side-nav--breakpoint--s: 'md';
$layout__page--background-color: $c-color__neutral--grey--05;
$layout__page--padding-v--xs: $size--lg + 4;
$layout__page--padding-v: 36px;
$layout__page--padding-h: $size--lg + 4;

// Shadows
$shadow__primary: 0 $c-space--xs $c-space--ml 0 rgba($c-color__neutral--black, 0.06) !default;
$shadow__main: 0 0 12px 1px rgba(0, 0, 0, 0.18) !default;
$shadow__main--offset-y: 0 10px 12px 1px rgba(0, 0, 0, 0.18) !default;

// z-index
$c-modal--z-index: 10000 !default;

//Opacity
$c-opacity--disabled--dark: 0.7,
