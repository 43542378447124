@import "styles/config";
@mixin playlists-select-item {
  .mcp-playlist__playlists-select-item {
    display: flex;
    height: 100%;
    align-items: center;
    &--icon {
      width: $c-size--sm + 2;
      display: flex;
      align-items: center;
      justify-content: center;
      @content;
    }

    &--name {
      margin-left: $c-space--md;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@mixin playlist-sort-select-item {
  .mcp-playlist__sort-select-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &--label {
      line-height: 42px;
      vertical-align: middle;
    }
  }
}
