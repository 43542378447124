@import "../config";
@import 'styles/_legacy/mixins';
@import 'framework/effects/config-gradient';
@import 'framework/iconography/patterns';

$button-border--width: $size--xxs !default;
$button-border--radius: $size--xs !default;

.button {
  display: inline-block;
  line-height: 1.4em;
  min-width: $size--xxl + $size--lg;
  font-family: $c-font-family--accent;
  font-size: ($size--lg - $size--sm);
  padding: ($size--sm + $size--xs) $size--md;
  border-radius: $button-border--radius;
  outline: none;
  transition-property: background-color, color, border;
  transition-duration: $transition-duration--sm;
  text-align: center;
  cursor: pointer;

  &[disabled],
  &.disabled {
    pointer-events: none;
  }
}

.button--rounded {
  display: flex;
  align-items: center;
  border-radius: $c-border-radius--rounded;
  text-transform: uppercase;
  padding: $c-space--sm+2 $c-space--ml;

  &::before {
    border-radius: $c-border-radius--rounded !important;
  }

  &:hover {
    background-color: $c-color__neutral--grey--70;
  }

  &:active,
  &.active {
    background-color: $c-color__neutral--white;
    color: $c-color__neutral--black;
  }

  &.disabled,
  &:disabled {
    border-color: $c-color__neutral--grey--70;
    color: $c-color__neutral--grey--70;
    background-color: $c-color__neutral--black;
    opacity: 1;
  }

  > * {
    line-height: 1;
  }

  i {
    margin-right: $c-space--lg;
  }
}

.button-primary {
  @extend .button;
  position: relative;
  border: $button-border--width solid transparent;
  color: $c-color__neutral--white;
  z-index: 0;
  background-image: $c-linear-gradient--secondary;
  background-size: calc(100% + #{$button-border--width * 2});
  background-position-x: -$button-border--width;

  &::before {
    position: absolute;
    content: "";
    top: -$button-border--width;
    right: -$button-border--width;
    left: -$button-border--width;
    bottom: -$button-border--width;
    border-radius: $button-border--radius;
    background-color: $c-color__brand--primary--dark;
    z-index: -1;
    transition: opacity $transition-duration--sm;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active {
    box-shadow: inset 0 $size--sm $size--sm rgba($c-color__neutral--black, 0.125);
  }

  &[disabled],
  &.disabled {
    background: $c-color__neutral--grey--20;
  }
}

.button-secondary {
  @extend .button;
  border: $c-border;
  border-color: $c-color__neutral--white;
  color: $c-color__neutral--white;
  background-color: $c-color__neutral--black;
}

.button-outline {
  @include button-gradient;
  @extend .button;
  color: $c-color__brand--primary;

  &:hover {
    color: $c-color__neutral--white;
  }

  &:active,
  &.active {
    box-shadow: inset 0 $size--sm $size--sm rgba($c-color__neutral--black, 0.125);
  }

  &[disabled],
  &.disabled {
    border-color: $c-color__neutral--grey--05;
    color: $c-color__neutral--grey--05;
  }
}

.web-button {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  height: 52px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  cursor: pointer;

  &:before {
    border-right-color: transparent !important;
    transform: translateX(-100%);
  }

  &:after {
    border-left-color: transparent !important;
    transform: translateX(100%);
  }

  &:hover {
    &:before,
    &:after {
      transform: translateX(0);
    }
  }

  > span {
    display: inline-block;
    width: 100%;
    line-height: 50px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-style: solid;
    border-width: 26px;
  }

  &,
  & > *,
  &:before,
  &:after {
    transition: 0.5s ease-in-out;
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
  }
}

.web-button-primary {
  @extend .web-button;

  border-width: 0;
  color: white;
  background-color: #009c39;

  &:hover {
    color: white;
    background-color: transparent;
  }

  &:before,
  &:after {
    border-color: black;
  }

  &--dark {
    @extend .web-button-primary;
    border: solid 1px black;

    &:hover {
      border: solid 1px white;
    }
  }

  &[disabled],
  &.disabled {
    background-color: $c-color__neutral--grey--40;
    border-color: $c-color__neutral--grey--40;
    color: white;
  }
}

.web-button-secondary {
  //poner disabled en esta clase
  @extend .web-button;

  border-color: transparent;
  color: black;
  background-color: white;

  &:hover {
    border-color: white;
    color: white;
    background-color: transparent;

    &:before,
    &:after {
      border-color: transparent;
    }
  }

  &:before,
  &:after {
    border-color: black;
    opacity: 0.5;
  }
}

.web-button-outline {
  //poner disabled en esta clase
  @extend .web-button;

  border-color: white;
  color: white;
  background-color: transparent;

  &:hover {
    border-color: black;
    color: black;
    background-color: white;
  }

  &:before,
  &:after {
    border-color: white;
  }
}

%button-primary-regular-xs {
  @extend .button-primary;
  width: 100%;

  font-family: $c-font-family--regular;
  font-size: $c-font-size--xs;
}
