@import "styles/config";
@import "styles/_legacy/mixins";
@import "styles/_legacy/widths";
@import "styles/_legacy/hidden";
@import "styles/main";
@import "styles/components/buttons";
@import "framework/responsive/tools-mq-from";
@import "styles/components/playlist-container";
@import "styles/themes/theme-lato";
@import "framework/elements/tools-scrollbar";

/* You can add global styles to this file, and also import other style files */

/* Angular CDK */
@import '@angular/cdk/overlay-prebuilt.css';

/* Global
------------------------------------------------------------------*/

body.is-windows-os {
  // Override Scrollbar
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 12px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(0 0 0 / 20%);
    border-radius: 8px;
    background-clip: content-box;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(0 0 0 / 40%);
    transition: 0s;
  }
}

body {
  background-color: $layout__page--background-color;
  overflow: initial;
  position: initial;
  font-family: $c-font-family--primary;

  .ui-widget {
    font-family: $c-font-family--primary;
  }

  .pi {
    font-size: $c-font-size--md;
  }
}

h1 {
  font-size: 40px;
  line-height: 38px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
  line-height: 35px;
}

h4 {
  letter-spacing: 1.5px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  margin-top: 10px;
}

p {
  color: #fff;
}

a {
  font-size: 16px;
  text-decoration: none !important;
}

/*Small devices (Low-end Windows Phone and Iphone (WVGA-DVGA)) */
@media (min-width: 481px) and (max-width: 640px) {
  .navbar-nav > li > a {
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

/* Medium devices (High-end Windows Phone (DVGA-WXGA)) */
@media (min-width: 641px) and (max-width: 1186px) {
  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .ui-dialog-content ui-widget-content {
    height: 500px;
    overflow: scroll;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-size: 16px;
  line-height: 24px;
}

.ui-dialog .ui-dialog-titlebar {
  display: none;
}

.ui-dialog.ui-widget .ui-dialog-content {
  padding: 1em 4.5em;
}

.ui-dialog-mask {
  background-color: rgba(0, 0, 0, 0.37);
}

/*scroll x oculto*/
body.no-scroll-x {
  overflow-x: hidden;
}

/*scroll y oculto*/
body.no-scroll-y {
  overflow-y: hidden;
}


.document--modal {
  overflow: hidden;
}

.append-to {
  &-body--modal {
    overflow: hidden;
    @include t-from(xl) {
      overflow: auto;
    }
  }

  &--modal {
    overflow: hidden !important;
    position: relative;
  }
}

body.document--filters-z-index {
  .c-players-list__header,
  .c-teams-list__header,
  .c-matches-list__header,
  .c-videotutorials-list__header {
    z-index: $layout__side-nav--z-index + 1;
  }
}

/* ESTILOS PORTAL */

.btn-default[disabled]:hover {
  background-color: #dbdde0;
  border-color: #dbdde0;
}

.form-control {
  box-shadow: none !important;
}

.form-control[disabled] {
  background-color: white !important;
}

@media (max-width: 1186px) {
  .navbar-collapse.collapse.in.show {
    background-color: white;
    padding: 0 !important;
    position: absolute;
    right: 0;
    width: 200px;
  }
}

@media (max-width: 767px) {
  .navbar-collapse.collapse.in.show {
    padding: 0 0 0 24px !important;
  }
}

@media (max-width: 340px) {
  .navbar-collapse.collapse.in.show {
    padding: 0 !important;
    width: 105vw;
  }
}

@media (min-width: 992px) {
  .sub-container-padding {
    padding-left: 139px;
  }
}

.ui-chkbox-label {
  color: #fff;
  font-family: $c-font-family--primary;
  font-size: 13px;
}

label.ui-chkbox-label {
  font-weight: 500;
}

.ui-overflow-hidden {
  overflow-y: hidden;
}

.ui-shadow {
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 16px 0 !important;
}

// FIXME: remove after directives refactor
button.disabled {
  cursor: no-drop;
  opacity: 0.5;
}

input.ui-inputtext.ui-widget.ui-state-default.ui-corner-all {
  width: 100%;
}

// GENERAL
.p-tooltip {
  font-family: $c-font-family--accent;
  font-size: $size--md + $size--xxs;
  min-width: $size--xxs;
  z-index: $c-modal--z-index !important;

  .p-tooltip-text {
    background-color: $c-color__neutral--grey--70;
    font-family: $c-font-family--black;
    font-size: $size--md + $size--xs;
    padding: $size--sm - $size--xxs $size--md - $size--xxs;
  }

  .p-tooltip-arrow {
    display: none;
  }
}

app-loading.full-height .loading__wrapper {
  height: 100vh;
}

.grecaptcha-badge {
  visibility: collapse;
}

body.overflow-hidden {
  overflow-y: hidden;
  @include t-from($layout__side-nav--breakpoint) {
    overflow-y: unset;
  }
}

body.overflow-hidden-always {
  overflow-y: hidden;
}

.issue-form-tooltip.p-tooltip {
  font-family: $c-font-family--regular;
  font-size: $c-font-size--xs;
  margin-top: -$c-space--md;
  max-width: $c-size--xl;

  .p-tooltip-text {
    background-color: #4a4a4a;
    border: solid 1px $c-color__neutral--white;
    color: $c-color__neutral--white;
    line-height: $c-size--xs + 4;
    padding: 11px 16px 21px;
  }

  .p-tooltip-arrow {
    border-top-width: 0 !important;
    bottom: -4.3px;
    display: block;
    height: 8px;
    margin: 0 auto;
    margin-left: -11px;
    overflow: hidden;
    position: absolute;
    width: 21px;

    &::before, &::after {
      border-left: 8px dotted transparent;
      border-right: 8px dotted transparent;
      border-top: 8px solid $c-color__neutral--white;
      content: "";
      height: 0;
      position: absolute;
      width: 0;
    }

    &::after {
      border-top: 8px solid #4a4a4a;
      bottom: 1px; /* offset sets width of border */
    }
  }
}

.issue-form-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #4a4a4a !important;
}

.mc-media-item__text {
  font-size: 95% !important;
  line-height: 1.2;
  text-transform: uppercase;
}

.c-asset-container__item-icon-tooltip.p-tooltip-top {
  border-radius: 0;
  font-size: $c-font-size--xs;

  .p-tooltip-text {
    background-color: $c-color__neutral--grey--75;
    border: solid $c-border-size $c-color__neutral--grey--65;
    border-radius: 0;
    padding: ($c-space--xs + 1) ($c-space--md + 1);
  }

}

// primeng
.p-element *:not(.pi):not(.vjs-icon-placeholder):not(.vjs-play-progress):not(.c-side-navigation__live) {
  font-family: #{$c-font-family--regular};
}

.mc-overlay-panel {
  overflow: initial !important;
}

.mcui-dialog.mcp-playlist-dialog {
  max-width: 90%;



  mcui-icon {
    --mcui-icon-size: #{$c-font-size--md}
  }

  &.m-app--dark {
    background-color: $c-color__neutral--grey--85;
    color: $c-color__neutral--white;

    *{

      @include t-dark-scrollbar;
    }

    mcui-icon {
      --mcui-icon-color: #{$c-color__neutral--white}
    }

    input{
      background: $c-color__neutral--black;
      color: $c-color__neutral--white;
    }

    mcui-confirm-dialog {
      --mcui-confirm-dialog__footer-button--cancel-color: #{$c-color__neutral--white};
    }

    .mcui-confirm-dialog__footer-button--cancel {
      --mcui-button-background-color--hover: #{$c-color__neutral--grey--80};

      &:hover {
        --mcui-button__text-color: #{$c-color__neutral--white};
      }
    }
  }

  .mcui-dialog-title {
    font-size: $c-font-size--lg + 2;
    font-family: var(--t-font-family--primary);
  }
}


.mcui-dialog.mcp-dialog-playlist-filter {
  width: $c-size--xxl + $c-size--lg - 10;
  height: $c-size--xxl + $c-size--lg + $c-size--xxs;
}

.mcui-dialog.mcp-dialog-update-playlist-clip-comment {
  width: $c-size--xxl + $c-size--xl + $c-size--md;
  height: $c-size--xl + $c-size--lg + $c-size--md;
}

.mcui-dialog.mcp-dialog-playlist-share {
  width: $c-size--xxl + $c-size--lg;
  height: $c-size--xl + $c-size--lg + $c-size--md + $c-size--xs;
}

.mcui-dialog.mcp-metric-modal {
  width: $c-size--ml * 5;
}

//mcui-lib
.mcui-dialog {
  border-radius: $c-border-radius;
}

.mcui-select__panel.mcui-select-panel--common {
  --mcui-select__panel-margin-top: #{$c-size--xxs + 2} !important;
  --mcui-select__panel-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
  --mcui-select__panel-border-top: none !important;
  border-radius: 4px;
  background-color: $c-color__neutral--white;
  padding: 7px 0;

  mcui-select-option {
    --mcui-select-option__background-color--active: #f5f5f5;

    .mcui-select-option {
      font-family: var(--t-font-family--semi-accent), sans-serif;
    }
  }
}

.mcui-select__panel.mcp-playlist__select-panel {
  --mcui-select__panel-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25) !important;
  --mcui-select__panel-margin-top: #{$c-size--xxs - 2} !important;
  --mcui-select__panel-border-top: none !important;

  &.m-app--dark {
    mcui-select-option {
      --mcui-select-option__background-color: #282828;
      --mcui-select-option__color: #{$c-color__neutral--white};
      --mcui-select-option__background-color--active: #{$c-color__neutral--grey--75};

      .mcp-playlist__playlists-select-item--icon {
        mcui-icon {
          --mcui-icon-color: #{$c-color__neutral--white};
        }
      }
    }
  }

  mcui-select-option {
    --mcui-select-option__padding: 0 #{$c-size--xs + 4};
    --mcui-select-option__background-color: #{$c-color__neutral--white};
    --mcui-select-option__color: #{$c-color__neutral--black};
    --mcui-select-option__background-color--active: #f2f2f2;

    .mcui-select-option {
      height: $c-size--sm + $c-size--xs - 3;
    }

    @include playlists-select-item {
      mcui-icon {
        --mcui-icon-size: #{$c-size--sm + 2};

        svg {
          height: $c-size--xs + 10;
        }
      }
    }

    @include playlist-sort-select-item;
  }
}

.mcui-loader.overlay {
  z-index: $c-z-index--2;
  border-radius: 10px;
}

mcui-loading {
  width: initial !important;
}

.mcui-dialog.mcp-widget-dialog {
  min-width: 350px;
}

.highlighted-text {
  line-height: 1em;
  font-weight: bolder;
  text-decoration: underline;
}

.mcui-dialog-mask.mcp-dialog-widget-selector-mask {
  z-index: 55 !important;

  .mcui-dialog {
    z-index: 55 !important;
    min-height: 85vh;
  }
}

.mcui-menu.m-app--dark {
  --mcui-menu-background: #282828;

  mcui-menu-item {
    --mcui-menu-item__label-color: #{$c-color__neutral--white};
    --mcui-menu-item-background-color--hover: #{$c-color__neutral--grey--75};
  }
}


.remove-scrolls{
  &,
  * {
    overflow: unset !important;
  }
}


.mcui-toast{
  margin-top: 10px;
  margin-right: 10px;
}
